import React, { useState, useEffect } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import axios from 'axios'

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { getProducts, Product } from '../../utils/getData/Products';
import LoadingScreen from '../loading/LoadingScreen';
import getLocationTeam from '../../utils/getData/LocationTeam';
import { Logout } from '../auth/Logout';
import HowToPlayButton from '../../subComponents/howtoplay/HowToPlayButton';

const Team: React.FC = () => {
  const { team = "default" } = useParams();
  const [products, setProducts] = useState<Product[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [ErrorFlag, setErrorFlag] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [teamColor, setTeamColor] = useState("default");
  const location = useLocation();

  var teamList: Object;
  if (typeof process.env.REACT_APP_TEAMNAMES === "string") {
    teamList = JSON.parse(process.env.REACT_APP_TEAMNAMES);
  } else {
    teamList = [];
  }

  const [isValidTeam, setIsValidTeam] = useState<boolean>(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProducts(team);
        setProducts(response);
        setLoading(false);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const statusCode = error.response.status;
          switch (statusCode) {
            case 401:
              setErrorFlag(true);
              break;
            case 500:
              setErrorFlag(true);
              setErrorMessage("サーバーエラーが発生しました。しばらく時間をおいてから再度お試しください。");
              break;
            // その他のステータスコードに対する処理...
            default:
              setErrorFlag(true);
              setErrorMessage("予期せぬエラーが発生しました。しばらく時間をおいてから再度お試しください。");
          }
        }
      }
    };
    // 存在しないチームが指定されている場合何もしない
    if (team in teamList) {
      fetchData();
      const teamName = getLocationTeam(location);
      setTeamColor(teamName);
    } else {
      setIsValidTeam(false);
    }

  }, [team, teamList]);

  if (ErrorFlag) {
    return <Logout reason="通信に失敗しました。再度ログインしてください。" />
  }

  // 存在しないチームが指定されているのでトップへリダイレクト
  if (!isValidTeam) {
    return <Navigate to="/" />;
  }

  if (loading) {
    return (
      <>
        <LoadingScreen />
        <p>{errorMessage}</p>
      </>
    );
  }

  return (
    <>
      <img className='my-3 d-block max-w-350 mx-auto' src={`/image/${team}/banner.png`} alt="banner" />
      <div className='mainSection'>
        <div className='d-flex justify-content-center flex-wrap'>
          {
            products?.map(product => (
              product.is_sale && (
                <Card className="m-2" key={product.product_code} style={{ width: '18rem' }}>
                  <Card.Img variant="top" src={`/image/${product.team_name}/${product.product_code}/banner.png`} />
                  <Card.Body>
                    <Card.Title>{product.name}</Card.Title>
                    <Button variant="primary" className={`bg-${teamColor}-primary`} href={`/${product.team_name}/${product.product_code}`}>購入ページへ</Button>
                  </Card.Body>
                </Card>
              )
            ))
          }
        </div>
        {products !== undefined ? products.every(product => !product.is_sale) ?
          <div className='fs-3'>準備中/完売</div>
          :
          <>
            <HowToPlayButton teamColor={teamColor} width="200" />
          </>
          :
          <div className='fs-3'>準備中/完売</div>}
      </div>
    </>
  );
};

export default Team;




