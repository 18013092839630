import React from 'react';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Carousel } from 'react-bootstrap';

import '../../styles/top.sass'

const Top: React.FC = () => {
  var teamList: Object;
  if (typeof process.env.REACT_APP_TEAMNAMES === "string") {
    teamList = JSON.parse(process.env.REACT_APP_TEAMNAMES);
  } else {
    teamList = [];
  }


  return (
    <>
      <Carousel data-bs-theme="dark" fade>
        <Carousel.Item>
          <div className='d-flex justify-content-center'>
            <img className='carousel-image' src={`/image/TopCaroucel/1.jpg`} alt="" />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className='d-flex justify-content-center'>
            <img className='carousel-image' src={`/image/TopCaroucel/2.jpg`} alt="" />
          </div>
        </Carousel.Item>
      </Carousel>
      <div className='d-flex mainSection justify-content-center flex-wrap mb-3'>
        {
          Object.entries(teamList).map(([key, value], index) => (
            <Card key={index} className="m-2" style={{ width: '45%' }}>
              <Card.Img variant="top" src={`/image/${key}/logo.png`} />
              <Card.Body>
                <Card.Title className="text-center">{value}</Card.Title>
                <Button variant="primary" href={`/${key}/`} className='mx-auto'>チームサイトへ</Button>
              </Card.Body>
            </Card>
          ))
        }
      </div>
    </>

  );
};

export default Top;




